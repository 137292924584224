/**
 * Created by Andste on 2018/5/28.
 */

import request from '@/utils/request'
import { domain } from '@/../ui-domain'
import { RegExp } from '@/../ui-utils'
import * as API_goodsCategory from '@/api/goodsCategory'
export default {
	props: {
		// 选择器类型，后台管理：admin，商家中心：seller
		type: {
			type: String,
			default: 'admin'
		},
		// 显示dialog
		show: {
			type: Boolean,
			default: false
		},
		api: {
			type: String,
			default: 'admin/goods'
		},
		// 获取多个商品API
		multipleApi: {
			type: String,
			default: 'admin/goods/@ids/details'
		},
		// 分类API
		categoryApi: {
			type: String,
			default: 'admin/goods/categories/@id/children'
		},
		// 默认数据
		defaultData: {
			type: Array,
			default: () => ([])
		},
	},
	data() {
		return {
			/** 当前点击的1级分类索引*/
			activeCategoryIndex1: -1,

			/** 当前点击的2级分类索引*/
			activeCategoryIndex2: -1,
			/** 1级分类列表*/
			categoryListLevel1: [],

			/** 2级分类列表*/
			categoryListLevel2: [],

			/** 3级分类列表*/
			goodsList: [],
			/** 已选列表 */
			selectedList: [],
			dialogVisible: this.show,
			// 前台域名
			buyerDomain: domain.buyer_pc,
			baseInfoForm: {
				// category_id: ''
			}
		}
	},
	watch: {
		show(newVal) {
			this.dialogVisible = newVal
			if (this.goodsList.length === 0 && newVal === true) {
				this.GET_NextLevelCategory()
				this.$nextTick(() => {
					this.$selectedList = document.getElementById('goods-picker-selected-' + this._uid)
				})
			}
		},
		dialogVisible(newVal) {
			newVal === false && this.$emit('close')
		},
		'defaultData': 'defaultDataChanged'
	},
	methods: {
		/** 查询下一级 商城商品分类*/
		GET_NextLevelCategory(row, level) {
			this.loading = true
			const _id = row && row.category_id !== 0 ? row.category_id : 0
			API_goodsCategory.getGoodsCategoryLevelList(_id, { }).then((response) => {
				this.loading = false
				if (level === 1) {
					this.categoryListLevel2 = response
				}else if (!level) {
					this.categoryListLevel1 = response
				}
			})
		},
		/** 选择商城商品分类 */
		handleSelectCategory(row, index, level) {
			if (level === 1) {
				this.activeCategoryName1 = row.name
				this.activeCategoryName2 = this.activeCategoryName3 = ''
				this.activeCategoryIndex1 = index
				this.activeCategoryIndex2 = -1
			} else if (level === 2) {
				this.activeCategoryName2 = row.name
				this.activeCategoryName3 = ''
				this.activeCategoryIndex2 = index
			} else {
				this.activeCategoryName3 = row.name
			}
			// 设置当前商城分类ID
			// this.baseInfoForm.category_id = row.category_id
			this.GET_NextLevelCategory(row, level)
		},
		/** 确认 */
		handleConfirm() {
			if(this.activeCategoryIndex2 == -1){
				this.$message.error('请选择商家')
			}else{
				this.$emit('confirm', this.categoryListLevel2[this.activeCategoryIndex2])
				this.$emit('close')
			}
		},
	}
}
