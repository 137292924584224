/**
 * Created by Andste on 2018/5/16.
 */

import request from '@/utils/request'
import {api} from '@/../ui-domain'

/**
 * 获取公共页面楼层数据
 */
export function getFloorConfig(shop_id, type, card_id= null) {
  const card = card_id === null ? '' : `&card_id=${card_id}`
  return request({
    url: `/seller/pages/pageDataTh?shop_id=${shop_id}&floor_page_type=${type}`+card,
    method: 'get'
  });
}


/**
 * 企业端楼层数据
 * @param client_type
 * @param page_type
 */
export function getQFloor(client_type, page_type) {
  return request({
    url: `seller/pages/market/${client_type}/${page_type}`,
    method: 'get'
  })
}

/**
 * 获取楼层数据
 * @param client_type
 * @param page_type
 */
export function getFloor(client_type, page_type) {
  return request({
    url: `seller/pages/${client_type}/${page_type}`,
    method: 'get'
  })
}

/**
 * 获取楼层装修2.0开关状态
 */
export function floorVersions() {
  return request({
    url: '/seller/shops/getEnterpriseStoreyDecorateStatus',
    method: 'get'
  })
}

/**
 * 获取子楼层数据
 * @param client_type
 * @param page_type
 */
export function getSiteNavigations(id) {
  return request({
    url: `seller/pages/site-navigations/${id}`,
    method: 'get'
  })
}

/**
 * 修改楼层
 * @param client_type
 * @param page_type
 * @param params
 */
export function editSiteNavigations(params) {
  return request({
    url: `seller/pages/site-navigations/editPage/${params.id}`,
    method: 'put',
    data: params
  })
}

/**
 * 修改楼层
 * @param client_type
 * @param page_type
 * @param params
 */
export function editFloor(client_type, page_type, params) {
  return request({
    url: `seller/pages/${client_type}/${page_type}`,
    method: 'put',
    data: params
  })
}

/**
 * 获取焦点图位置列表
 * @param client_type
 */
export function getFocusPosition() {
  return request({
    url: 'seller/pages/focusPosition/listWithShop',
    method: 'get',
  })
}

/**
 * 获取焦点图列表
 * @param client_type
 */
export function getFocus(client_type, position_id) {
  return request({
    url: 'seller/pages/focus-pictures',
    method: 'get',
    params: {
      client_type,
      position_id
    }
  })
}

/**
 * 添加焦点图
 * @param params
 */
export function addFocus(params) {
  return request({
    url: 'seller/pages/focus-pictures',
    method: 'post',
    data: params
  })
}

/**
 * 获取焦点图详情
 * @param id
 */
export function getFocusDetail(id) {
  return request({
    url: `seller/pages/focus-pictures/${id}`,
    method: 'get'
  })
}

/**
 * 修改焦点图
 * @param id
 * @param params
 */
export function editFocus(id, params) {
  return request({
    url: `seller/pages/focus-pictures/${id}`,
    method: 'put',
    data: params
  })
}

/**
 * 删除焦点图
 * @param id
 */
export function deleteFocus(id, position_id) {
  return request({
    url: `seller/pages/focus-pictures/${id}`,
    method: 'delete',
    data: {position_id}
  })
}

/**
 * 获取集市焦点图列表
 * @param client_type
 */
export function getMarketFocus(client_type = 'PC') {
  return request({
    url: 'seller/pages/market/focus-pictures',
    method: 'get',
    params: {client_type}
  })
}

/**
 * 获取集市导航
 * @param client_type
 */
export function getNav(client_type = 'PC') {
  return request({
    url: 'seller/pages/market/site-navigations',
    method: 'get',
    params: {client_type}
  })
}

/**
 * 获取商品选择器数据
 * @params
 */
export function getShopGoods(params) {
  return request({
    url: 'seller/shopGoods',
    method: 'get',
    params: params
  })
}


/**
 * 获取图片库类型
 * @param id
 */
export function listCategory(params) {
  return request({
    url: `${api.base}/base/picture/listCategory`,
    method: 'get',
    params
  })
}

/**
 * 查询图片库图片列表
 * @param params
 * @returns {Promise<any>}
 */
export function listPicture(params) {
  return request({
    url: `${api.base}/base/picture/listPicture`,
    method: 'get',
    params
  })
}

/**
 * 设置主色调
 * @param params
 * @returns {AxiosPromise<any>|Promise<unknown>|*}
 */
export function setSubjectColor(params) {
  return request({
    url: 'seller/shops/saveCardColourTh',
    method: 'put',
    data: params
  })
}

/**
 * 获取主色调
 * @returns {AxiosPromise<any>|Promise<unknown>|*}
 */
export function getSubjectColor() {
  return request({
    url: 'seller/shops/getShopCardColourTh',
    method: 'get'
  })
}
