<template>
  <embed ref="svgIcon" :class="svgClass" :src="`/svg-icons/${iconClass}.svg`">
</template>

<script>
let index = 0;
let detectId = null;
const pagesMap = {};

function resetStyle() {
  const pages = Object.values(pagesMap);

  pages.forEach(p => {
    const ref = p.$refs.svgIcon;

    if (ref) {
      const doc = ref.getSVGDocument();
      if (doc) {
        const svg = doc.querySelector('svg');

        if (svg) {
          const style = getComputedStyle(ref);
          svg.style.transition = 'all 100ms';
          svg.style.fill = style.color;
        }
      }
    }
  });

  if (pages.length === 0) {
    detectId = null;
    return;
  }

  setTimeout(() => {
    requestAnimationFrame(resetStyle);
  }, 1000 / 10);

}

function detectStyle(page) {
  page.index = index++;
  pagesMap[page.index] = page;
  if (detectId) return;
  detectId = true;
  resetStyle();
}

function unDetectStyle(page) {
  delete pagesMap[page.index];
}

export default {
  name: 'svg-icon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String
    },
  },
  computed: {
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    }
  },
  mounted() {
    detectStyle(this);
  },
  destroyed() {
    unDetectStyle(this);
  }
}
</script>

<style lang="scss">
.svg-icon {
  width: 20px;
  height: 20px;
  vertical-align: -0.15em;
  overflow: hidden;
}
</style>
