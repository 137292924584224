import request from '@/utils/request'

/**
 * 获取企业下的店铺列表
 * @param params
 * @returns {Promise<any>}
 */
export function getStoreList(params) {
  return request({
    url: 'seller/shops/getShopChildList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取店铺分组列表
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCategoryList() {
  return request({
    url: 'seller/shops/cats',
    method: 'get',
    loading: false
  })
}

/**
 * 删除 商品分组
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function deleteGoodsCategory(params) {
  const ids = params.toString()
  return request({
    url: `seller/shops/cats/${ids}`,
    method: 'delete'
  })
}

/**
 * 商品分组 添加
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function addGoodsCategory(params) {
  return request({
    url: 'seller/shops/cats',
    method: 'post',
    data: params
  })
}

/**
 * 商品分组 编辑
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function updateGoodsCategory(ids, params) {
  return request({
    url: `seller/shops/cats/${ids}`,
    method: 'put',
    data: params
  })
}

/**
 * 发布商品 查询商品品类 分级查询（商城商品品类）
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCategoryLevelList(_id, params) {
  return request({
    url: `seller/goods/category/${_id}/children`,
    method: 'get',
    loading: false,
    data: params
  })
}

/**
 * 发布商品 查询商品品类 分级查询（商城商品品类）
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCategoryDepthLevelList(depth) {
  return request({
    url: `seller/goods/category/depth/${depth}`,
    method: 'get',
    loading: false,
  })
}
/**查询礼包品类 分级查询 动态加载
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGiftsCategoryLevelList(_id, params) {
  return request({
    url: `seller/goods/giftCategory/${_id}/children`,
    method: 'get',
    loading: false,
    data: params
  })
}
/**查询礼包品类 分级查询 全部加载
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getAllGiftsCategoryLevelList(_id, params) {
  return request({
    url: `seller/goods/giftCategory/${_id}/listWithChildren`,
    method: 'get',
    loading: false,
    data: params
  })
}