/**
 * Created by Andste on 2018/5/28.
 */

import request from '@/utils/request'
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  props: {
    // 显示dialog
    show: {
      type: Boolean,
      default: false
    },
    // 显示每一行的挑选按钮
    showChooseBtn: {
      type: Boolean,
      default: false
    },
    // 默认数据
    defaultData: {
      type: Array,
      default: () => ([])
    },
    // 排除
    excludeData: {
      type: Array,
      default: () => ([])
    },
    // 商品列表
    goodsApi: {
      type: String,
      default: `seller/shopCombo`
    },
    // 是否套餐商品
    showCombo: {
      type: Number,
      default: 0
    },
    // 自有商品分类
    sellerCategoryApi: {
      type: String,
      default: `seller/goods/category/depth/@depth`
    },

    // 获取集市导航APi   京东自有==
    navigationsApi: {
      type: String,
      default: `seller/pages/market/site-navigations`
      // params: { client_type :'PC' }
    }
  },
  data() {
    return {
      goodsSelectorDialog: $xDialog.create({
        title: '套餐礼包选择器',
        disableCancel: true,
        disableConfirm: true,
        width: '95vw',
        wrapperClass: 'goods-selector-dialog',
        afterDismiss: () => this.$emit('close', true),
      }),
      maxNumber: Math.pow(10, 12),
      params: {
        page_no: 1,
        page_size: 20,
        highEnterPrice: "",
        lowEnterPrice: "",
        highMktPrice: "",
        lowMktPrice: "",
        keyword: "",
        sort: "def_asc",
        comboStatus: "",
      },
      chooseChangeList: [],
      chooseAllList: [],
      tableData: {},
      tableHeight: 0,
      /** 加载状态 */
      loading: false,
      /** 商品列表 */
      goodsList: [],
      /** 已选列表 */
      selectedGoods: {},
      dialogVisible: this.show,
      canChangeVal: true,
    }
  },
  watch: {
    show(newVal) {
      this.dialogVisible = newVal;
      if (newVal) {
        if (this.goodsList.length === 0) {
          this.GET_Goods();
        }
        this.goodsSelectorDialog.display();
      } else {
        this.goodsSelectorDialog.dismiss();
      }
    },
    dialogVisible(newVal) {
      newVal === false && this.$emit('close')
    },
    'defaultData': 'defaultDataChanged'
  },
  filters: {
    formatPrice(price) {
      return '￥' + String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted() {
  },
  methods: {
    calcTableHeight() {
      return new Promise(resolve => {
        setTimeout(() => {
          // const height = (document.body.clientHeight - (10 + 30 + 54 + 72 + 55 + 20) - this.$refs.header.clientHeight);
          this.tableHeight = document.body.clientHeight - (54 + 72 + 55 + 20);
          resolve();
        });
      });
    },
    min(obj = {}, minKey = '', maxKey = '', disable0 = false) {
      const min = obj[minKey] || 0;
      const max = obj[maxKey] || this.maxNumber;
      if (parseFloat(min) > parseFloat(max)) {
        obj[minKey] = max;
      } else if (disable0 && min < 0) {
        obj[minKey] = 0;
      }
    },
    max(obj = {}, minKey = '', maxKey = '') {
      const min = obj[minKey] || 0;
      const max = obj[maxKey] || this.maxNumber;
      if (parseFloat(max) < parseFloat(min)) {
        obj[maxKey] = min;
      }
    },
    tabClick() {
      if (this.params.self_goods === '2') {
        this.params.goods_source = 1
      } else {
        this.params.goods_source = 0
      }
      this.GET_Goods();
    },
    //获取商品选择器数据
    GET_Goods() {
      this.showCombo && (this.params.is_combo = 0)
      this.params.category_path = (this.goodsTypeA ? this.goodsTypeA : '') + (this.goodsTypeB ? '|' + this.goodsTypeB : '') + (this.goodsTypeC ? '|' + this.goodsTypeC : '')
      this.params.market_enable = 1
      this.params.disabled = 1
      this.params.is_auth = 1
      this.params.shop_goods_status = 1

      let url = this.goodsApi;

      request({
        url,
        method: 'get',
        loading: true,
        params: this.params
      }).then(response => {
        this.tableData = response
        this.$nextTick(() => {
          this.canChangeVal = false;
          this.tableData.data.forEach(e => {
            let haveSameId = false;// 设置标志，回显已选数据期间不触发chooseChangeList赋值
            for (let index = 0; index < this.chooseChangeList.length; index++) {
              haveSameId = false;
              const ee = this.chooseChangeList[index];
              if (e.id === ee.id) {
                haveSameId = true;
                // 将新值赋值给旧值
                this.chooseChangeList[index] = e;
                this.$forceUpdate();
                break;
              }
            }
            if (haveSameId) {
              this.$refs.tables.toggleRowSelection(e, true);
            } else {
              this.$refs.tables.toggleRowSelection(e, false);
            }
          });
          this.canChangeVal = true;
        })
      })
    },
    //商品选择器一级数据
    goodsTypeAChange() {
      this.goodsTypeBList = this.goodsTypeAList.find(item => {
        return item.cat_id === this.goodsTypeA;
      }).children;
      this.goodsTypeB = '';
      this.goodsTypeC = '';
      this.goodsTypeCList = [];
    },
    goodsTypeAChangeClear() {
      this.goodsTypeBList = [];
      this.goodsTypeB = '';
      this.goodsTypeC = '';
      this.goodsTypeCList = [];
    },
    // 商品选择器二级数据
    goodsTypeBChange() {
      this.goodsTypeCList = this.goodsTypeBList.find(item => {
        return item.cat_id === this.goodsTypeB;
      }).children;
      this.goodsTypeC = '';
    },
    goodsTypeBChangeClear() {
      this.goodsTypeCList = [];
      this.goodsTypeC = '';
    },
    // 批量挑选抛出方法
    chooseAll() {
      if (this.chooseChangeList.length) {
        this.$emit('chooseAll', this.chooseChangeList);
        this.$emit('close');
      } else {
        this.$message.error('请先选择礼包');
      }
    },
    selectionChange(val) {
      if (this.canChangeVal) {
        this.chooseChangeList.push.apply(this.chooseChangeList, val);
        for (var i = 0; i < this.chooseChangeList.length; i++) {
          for (var j = i + 1; j < this.chooseChangeList.length; j++) {
            if (this.chooseChangeList[i]['id'] === this.chooseChangeList[j]['id']) {
              this.chooseChangeList.splice(j, 1);
              j--;
            }
          }
        }
      }
    },
    handleSelect(selection, row) {
      if (!selection.includes(row)) {
        for (let i = 0; i < this.chooseChangeList.length; i++) {
          const e = this.chooseChangeList[i];
          if (e.id === row.id) {
            this.chooseChangeList.splice(i, 1);
          }
        }
      }
    },
    handleSelectAll(selection) {
      if (!selection.length) {
        const rows = this.tableData.data;
        for (let tableIndex = 0; tableIndex < rows.length; tableIndex++) {
          const row = rows[tableIndex];
          for (let i = 0; i < this.chooseChangeList.length; i++) {
            const e = this.chooseChangeList[i];
            if (e.id === row.id) {
              this.chooseChangeList.splice(i, 1);
            }
          }
        }
      }
    },
    handleSortChange({order, prop}) {
      if (order) {
        this.params.sort = `${prop}_${order.includes('asc') ? 'asc' : 'desc'}`;
      } else {
        this.params.sort = '';
      }
      this.GET_Goods();
    },
    // 挑选抛出方法
    choose(row) {
      this.$emit('choose', row)
      this.$emit('close')
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_Goods();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_Goods();
    },
    /** 当默认数据发生改变 */
    defaultDataChanged(newVal) {
      if (newVal && newVal.length >= 0) {
        this.chooseChangeList = this.defaultData;
      } else {
        this.chooseChangeList = [];
      }
    },

  }
}
