/**
 * Created by 孙凯 on 2020/6/1.
 * tree
 * 主要用于树状选择
 */

import Vue from 'vue'
import Tree from './src/main'

Tree.install = () => {
  Vue.component(Tree.name, Tree)
}

export default Tree
