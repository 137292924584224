/**
 * Created by Andste on 2018/5/28.
 * 商品选择器
 * 依赖于element-ui
 */

import Vue from 'vue'
import StorePicker from './src/main.vue'

StorePicker.install = () => {
  Vue.component(StorePicker.name, StorePicker)
}

export default StorePicker
