import {BehaviorSubject, Subject} from 'rxjs';

export type ThemeMode = 'light' | 'dark';

export interface UserProfilesAction {
  icon?: string;
  title?: string;
  disabled?: boolean;
  divided?: boolean;
  handler?: () => void;
}

export interface UserProfiles {
  nickname?: string;
  avatar?: string;
  actions?: UserProfilesAction[];
}

export class LayoutService {
  mode = new BehaviorSubject('dark' as ThemeMode);
  userProfiles = new BehaviorSubject({} as UserProfiles);
  onLogout = new Subject<any>();

  toggleMode() {
    switch (this.mode.getValue()) {
      case 'dark':
        this.mode.next('light');
        break;
      case 'light':
        this.mode.next('dark');
        break;
    }
  }

  setMode(mode: ThemeMode) {
    this.mode.next(mode);
  }

  setUserProfiles(profiles: UserProfiles) {
    this.userProfiles.next(profiles);
  }
}

export const $layout = new LayoutService();
