<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="805px"
  >
    <div slot="title" class="image-picker-title">图片上传</div>
    <div class="image-picker-body">
      <div v-show="curEdit" class="upload-box edit">
        <div class="el-upload-dragger">
          <div class="opt-body">
            <div class="inner-opt">
              <div v-for="(opt, index) in curEdit.operation" :key="index" class="item-opt">
                <div
                  v-if="opt.type === 'select'"
                  class="input-with-select el-input el-input--small el-input-group el-input-group--prepend"
                >
                  <div class="el-input-group__prepend">{{ opt.label }}</div>
                  <el-select v-model="opt.value" size="small" slot="append" placeholder="请选择"
                             @change="handleGoodsCategoryChange(opt)">
                    <el-option
                      v-for="op in opt.options"
                      :label="op.text"
                      :value="op.value"
                      :key="op.text"
                    ></el-option>
                  </el-select>
                </div>
                <el-input
                  v-else
                  placeholder="请输入内容"
                  v-model="opt.value"
                  size="small"
                  class="input-with-select"
                  @change="checkGoodsCategoryIsNumber(opt)"
                >
                  <template slot="prepend">{{ opt.label }}</template>
                </el-input>
              </div>
            </div>
          </div>
          <div class="opt-footer">
            <el-button type="primary" size="small" @click="handleSaveEdit">保存</el-button>
            <el-button size="small" @click="curEdit = ''">取消</el-button>
          </div>
        </div>
      </div>
      <div v-if="picStore==false">
        <div class="iconBox">
          <i class="el-icon-upload icon"></i>
        </div>
        <div style="position:relative;margin-top:20px;">
          <div class="chooseButtonbox">
            <el-button class="button" slot="tip" @click="openImgStore">图片库选择</el-button>
          </div>
          <el-upload
            class="upload-box"
            style="margin-bottom:20px;padding:40px 0;"
            :limit="limit"
            :multiple="multiple"
            :show-file-list="true"
            list-type="picture-card"
            :on-success="onSuccess"
            :on-remove="onRemove"
            :on-preview="handleEditItem"
            :on-progress="handleProgress"
            :action="MixinUploadApi"
            :on-exceed="() => { $message.error('文件数量超过限制！') }"
            :file-list="fileList"
          >
            <el-button size="small" type="primary" style="position:absolute;left:30%;top:0;">点击上传</el-button>
          </el-upload>
        </div>
      </div>
      <!--  <el-upload
        ref="elUpload"
        class="upload-box"
        drag
        :limit="limit"
        :multiple="multiple"
        :show-file-list="true"
        list-type="picture-card"
        :on-success="onSuccess"
        :on-remove="onRemove"
        :on-preview="handleEditItem"
        :action="MixinUploadApi"
        :on-exceed="() => { $message.error('文件数量超过限制！') }"
        :file-list="fileList"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>-->

      <div v-if="picStore==true">
        <div class="conditions">
          <span>图片库类型：</span>
          <el-select
            style="width:120px;"
            size="small"
            placeholder="全部"
            @change="typeOptionsChange()"
            v-model="params.category_id"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.category_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="imgBox">
          <div class="img" v-for="(item,index) in tableData.data" :key="index" v-if="tableData.data.length!=0">
            <img :src="item.img_url" :key="item.id" @click="chooseImg(item)"/>
            <div class="chooseBox" v-if="item.choose=='1'">
              <i class="choose"></i>
            </div>
          </div>
          <div class="imgNull" v-if="tableData.data.length==0">暂无数据</div>
        </div>
        <div style="overflow:hidden;">
          <el-pagination
            style="float:right;"
            v-if="tableData.length !== 0"
            slot="pagination"
            @current-change="handlePageCurrentChange"
            :current-page="tableData.page_no"
            @size-change="handlePageSizeChange"
            :page-size="tableData.page_size"
            :layout="MixinTableLayout"
            :total="tableData.data_total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div slot="footer" class="image-picker-footer">
      <div class="upload-status-num">
        <div v-if="!picStore">
          最多可上传个数：
          <span>{{ limit }}</span>
          已上传个数：
          <span>{{ nums.successUpload }}</span>
          还可上传个数：
          <span>{{ nums.canUpload }}</span>
        </div>
      </div>
      <span>
        <el-button size="small"
                   @click="picStore?picStore=false:dialogVisible = false;fileList.length===0?btnShow=true:''">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm" :disabled="btnShow">确 定</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import * as API_Floor from '@/api/floor';
// Andste_TODO 2018/6/16: 图片选择组件需要重构
export default {
  name: 'EnImagePicker',
  props: {
    /** 显示dialog */
    show: {
      type: Boolean,
      default: false
    },
    /** 最大可上传个数 */
    limit: {
      type: Number,
      default: 10
    },
    /** 是否可多选 */
    multiple: {
      type: Boolean,
      default: false
    },
    /** 默认数据 */
    defaultData: {
      default: () => []
    },
    /** 自定义参数 */
    operation: {
      type: Array,
      default: () => [
        {
          label: '操作类型',
          name: 'opt_type',
          type: 'select',
          options: [
            {
              text: '无操作',
              value: 'NONE'
            },
            {
              text: '链接地址',
              value: 'URL'
            },
            {
              text: '关键字',
              value: 'KEYWORD'
            },
            {
              text: '商品序号',
              value: 'GOODS'
            },
            {
              text: '店铺编号',
              value: 'SHOP'
            },
            {
              text: '商品分类',
              value: 'CATEGORY'
            }
          ],
          value: 'NONE'
        },
        {
          label: '链接值',
          name: 'opt_value'
        }
      ]
    }
  },
  data() {
    return {
      dialogVisible: this.show,
      fileList: [],
      dataMap: new Map(),
      curEdit: '',
      nums: {
        successUpload: 0,
        canUpload: 0
      },
      picStore: false,
      paramsType: {
        page_no: 1,
        page_size: 1000
      },
      params: {
        sn: '1',
        page_no: 1,
        page_size: 12,
        category_id: ''
      },
      typeOptions: [],
      tableData: {data: []},
      chooseImgJson: {},
      btnShow: true,
      isGoodsCategory: false
    };
  },
  watch: {
    fileList(newVal) {
      if (newVal && newVal.length && newVal.length > 0) {
        this.btnShow = false;
      } else {
        this.btnShow = true;
      }
    },
    show(newVal) {
      this.dialogVisible = newVal;
      this.chooseImgJson = {};
      this.params.category_id = '';
      this.picStore = false;
      this.GET_listCategory();
      this.GET_listPicture();
      if (newVal === false) {
        this.curEdit = '';
        this.fileList = [];
        this.dataMap = new Map();
      }
      this.countNums();
    },
    dialogVisible(newVal) {
      newVal === false && this.$emit('close');
    },
    defaultData(newVal) {
      if (!newVal) return;
      this.fileList = JSON.parse(JSON.stringify(newVal));
      setTimeout(() => {
        this.fileList.forEach(item => {
          this.onSuccess(
            {url: item.url},
            {
              uid: item.uid,
              url: item.url
            }
          );
        });
      });
    }
  },
  mounted() {
    this.GET_listCategory();
    this.GET_listPicture();
    this.countNums();
  },
  methods: {
    handleGoodsCategoryChange(opt) {
      if (opt.value == 'CATEGORY') this.isGoodsCategory = true
    },
    checkGoodsCategoryIsNumber(opt) {
      if (this.isGoodsCategory) {
        opt.value = opt.value.replace(/[^0-9]/g, '')
      }
    },
    /** 文件上传时的钩子 */
    handleProgress() {
      this.btnShow = true;
    },
    /** 编辑自定义参数 */
    handleEditItem(file) {
      const {uid} = file;
      const curData = JSON.parse(JSON.stringify(this.dataMap.get(uid)));
      const deTarget = this.fileList.filter(item => item.uid === uid)[0];
      if (deTarget && deTarget.opt) {
        curData.operation.forEach(opt => (opt.value = deTarget.opt[opt.name]));
      }
      this.curEdit = curData;
      // 判断是否是分类类型的编辑
      this.curEdit.operation.forEach(opt => {
        if (opt.type === 'select' && opt.value == 'CATEGORY') this.isGoodsCategory = true
      })
    },
    /** 图片上传成功 */
    onSuccess(response, file, fileList) {
      const obj = {
        response,
        uid: file.uid,
        blob: file.url,
        operation: JSON.parse(JSON.stringify(this.operation))
      };
      this.dataMap.set(file.uid, obj);
      if (fileList) {
        this.fileList = fileList;
      }
      this.countNums();
    },
    /** 移除时触发 */
    onRemove(file, fileList) {
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileList.splice(index, 1)
        }
      });
      this.dataMap.delete(file.uid);
      this.countNums();
    },
    /** 保存自定义参数 */
    handleSaveEdit() {
      this.dataMap.set(this.curEdit.uid, this.curEdit);
      this.curEdit = '';
    },
    /** 确认 */
    handleConfirm() {
      if (this.picStore == false) {
        const list = [];
        console.log(this.dataMap);
        this.dataMap.forEach((value, key, map) => {
          const opt = {};
          value.operation.forEach(item => (opt[item.name] = item.value));
          list.push({
            uid: key,
            blob: value.blob,
            response: value.response,
            operation: opt
          });
        });
        this.$emit('confirm', list);
        this.$emit('close');
        if (this.$refs['elUpload']) {
          this.$refs['elUpload'].clearFiles();
        }
        this.dataMap = new Map();
      } else {
        if (this.chooseImgJson.img_url) {
          this.fileList.push({
            status: 'success',
            uid: new Date().getTime(),
            url: this.chooseImgJson.img_url,
            opt: {opt_type: 'NONE'}
          })
          this.onSuccess(
            {
              url: this.chooseImgJson.img_url,
              file_path: this.chooseImgJson.img_url,
            },
            {
              uid: new Date().getTime(),
              url: this.chooseImgJson.img_url
            },
            this.fileList
          );
        } else {
          if (this.fileList.length == 0) {
            this.btnShow = true;
          }
        }
        this.picStore = false;
      }
    },
    //原有计数方法，确定新方法没有问题后，可以删除原方法，
    // countNums() {
    //   this.nums = {
    //     successUpload: this.dataMap.size,
    //     canUpload: this.limit - this.dataMap.size
    //   };
    // },
    countNums() {
      this.nums = {
        successUpload: this.fileList.length,
        canUpload: this.limit - this.fileList.length
      };
    },
    GET_listCategory() {
      API_Floor.listCategory(this.paramsType).then(res => {
        this.typeOptions = res.data;
      });
    },
    GET_listPicture() {
      API_Floor.listPicture(this.params).then(res => {
        this.tableData = res;
        this.tableData.data.forEach(item => {
          this.$set(item, 'choose', '0');
        });
        this.tableData.data.forEach(item => {
          this.$set(item, 'choose', '0');
        });
        this.tableData.data.forEach(item => {
          this.$set(item, 'choose', '0');
        });
        this.tableData.data.forEach(item => {
          this.$set(item, 'choose', '0');
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_listPicture();
    },
    handlePageCurrentChange(val) {
      this.params.page_no = val;
      this.GET_listPicture();
    },
    typeOptionsChange() {
      this.GET_listPicture();
    },
    chooseImg(row) {
      this.tableData.data.forEach(item => {
        this.$set(item, 'choose', '0');
      });
      this.$set(row, 'choose', '1');
      this.chooseImgJson = row;
    },
    openImgStore() {
      if (this.fileList.length === this.limit) {
        this.$message.error('文件数量超过限制！');
        return false;
      }
      this.btnShow = false;
      this.picStore = true;
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
$border-style: 1px solid #ccc;
.image-picker-title {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: $border-style;
}

.image-picker-body {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 10px;
}

/deep/ {
  .el-dialog__body {
    padding: 0 20px;
  }

  .upload-box {
    display: flex;
    flex-direction: column-reverse;
    align-self: center;

    &:hover .inner-opt {
      border-color: #409eff;
    }

    &.edit .el-upload-dragger {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 99;
      margin-left: -300px;
      cursor: auto;
      text-align: left;
    }

    .el-icon-zoom-in:before {
      content: "\E616";
    }
  }

  .el-upload-list {
    display: block;
    max-height: 200px;
    min-width: 765px;
    min-height: 180px;
    overflow-y: scroll;
    border: 1px solid #d9d9d9;
    padding: 10px 0 10px 10px;

    &:hover {
      border-color: #409eff;
    }
  }

  .el-upload {
    width: auto;
    height: auto;
    line-height: normal;
    border: none;
    align-self: center;
    margin-bottom: 10px;
  }

  .el-upload-dragger {
    width: 600px;
  }

  .el-upload-list__item {
    width: 142px;
    height: 142px;
  }

  .opt-body {
    height: 130px;
    margin: 10px 10px 0 10px;
  }

  .inner-opt {
    width: 100%;
    height: 100%;
    border-bottom: 1px dashed #d9d9d9;
    overflow-y: auto;
  }

  .item-opt {
    display: inline-block;
    position: relative;
    width: 284px;
    font-size: 12px;
    margin-bottom: 10px;

    &:nth-child(2n) {
      margin-left: 10px;
    }

    .el-input-group--prepend .el-select .el-input.is-focus .el-input__inner {
      border-color: #409eff;
    }
  }

  .opt-footer {
    height: 40px;
    text-align: center;

    .el-button {
      width: 78px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      margin-top: 5px;
    }
  }
}

.image-picker-body {
  min-height: 350px;
}

.upload-status-num {
  font-size: 14px;

  span {
    color: red;
    margin-right: 10px;
  }
}

.image-picker-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: $border-style;
}

.icon {
  display: inline-block;
  font-size: 67px;
  color: #c0c4cc;
  margin: 0 auto;
  line-height: 50px;
}

.el-upload-class {
  display: flex;
  flex-direction: column-reverse;
  align-self: center;
}

.iconBox {
  display: flex;
  justify-content: center;
  align-self: center;
}

.chooseButtonbox {
  position: absolute;
  top: 0;
  right: 30%;
  width: auto;
  height: auto;
  line-height: normal;
  border: none;
  align-self: center;
  margin-bottom: 10px;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  outline: none;
  font-size: 14px;
}

.button {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #1a43a9;
  border-color: #1a43a9;
}

.imgBox {
  padding: 10px 0 10px 10px;
  width: 770px;
  margin: 20px 0;
  height: 260px;
  overflow: hidden;
  border: 1px solid #ccc;

  .img {
    width: 108px;
    height: 108px;
    margin-right: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    position: relative;
    float: left;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 100%;
    height: auto;
    display: inline-block;
  }

  .imgNull {
    width: 100%;
    height: 100%;
    line-height: 240px;
    text-align: center;
    font-size: 30px;
    color: #ccc;
  }

  .chooseBox {
    position: absolute;
    right: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    line-height: inherit;
    font-weight: 700;
    box-sizing: inherit;
    vertical-align: middle;

    .choose:before {
      content: "\E611";
    }

    .choose {
      color: #fff;
      font-size: 12px;
      margin-top: 11px;
      transform: rotate(-45deg);
      font-family: "element-icons" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: baseline;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      box-sizing: inherit;
      text-align: center;
      cursor: default;
      box-sizing: inherit;
      vertical-align: middle;
    }
  }
}
</style>
