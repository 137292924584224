<template>
    <el-select
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot></slot>
    </el-select>
</template>

<script>
export default {
  name: "ElSelectSelect"
};
</script>

<style lang="scss" scoped>
//::v-deep {
//  .el-input__suffix {
//    background-color:#EAF0FF;
//    right: 1px;
//    height: calc(100% - 2px);
//    top: 1px;
//    border-top-right-radius: 4px;
//    border-bottom-right-radius: 4px;
//    .el-icon-arrow-up::before{
//      content: "\e78f";
//    }
//    .el-input__icon{
//      color: #222;
//      transform-origin: 50% 53%;
//    }
//  }
//  .el-input__inner{
//    padding-left: 25%;
//  }
//}
//.center ::v-deep .el-input__inner{
//    padding-left: initial;
//    text-align: center;
//}
</style>
