/**
 * Created by Andste on 2018/5/3.
 * Modified by ChenAo on 2021/2/8.
 */
export default {
  setItem: (key, value) => {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },
  getItem: (key) => {
    let value = localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {
    }
    return value;
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
  clearAll: () => {
   localStorage.clear();
  },
}
