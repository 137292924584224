/**
 * Created by Andste on 2018/5/28.
 * 商品选择器
 * 依赖于element-ui
 */

import Vue from 'vue'
import ShopPicker from './src/main.vue'

ShopPicker.install = () => {
  Vue.component(ShopPicker.name, ShopPicker)
}

export default ShopPicker
