<template>
    <div>
        <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="80%"
        >
        <div class="df">
          <div class="xd">
            <el-tree highlight-current node-key="id" :show-checkbox="multi" :data="treeData" :props="defaultProps" @node-click="handleNodeClick"  ref="tree" @check-change="change"></el-tree>
          </div>
          <div class="xd">
            <div v-for="k in selectData" class="cc table-c">
              <span style="text-align: left;">{{k.label}}</span>
              <span  class="lj" @click="remove(k)">删除</span>
            </div>
          </div>
        </div>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{butText[0]}}</el-button>
          <el-button type="primary" @click="dialogVisible = false">{{butText[1]}}</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
    export default {
    name: 'Tree',
    components: {
    },
    props: {
      //树状图数据
      data: {
        type: Array,
        default: () => []
      },
      //控件显示与隐藏
      bool:{
        type:Boolean,
        default:false
      },
      //弹窗标题
      title:{
        type:String,
        default:"选择用户和部门"
      },
      //按钮text
      butText:{
        type:Array,
        default:()=>["取消","确定"]
      },
      //是否可多选
      multi:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        //树状数据
        treeData:this.data,
        //ele组件默认数据
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        //显示与隐藏参数
        dialogVisible:this.bool,
        selectData:[]
        
      }
    },
    watch: {
      //监听父组件传值情况
      bool(newVal){
        this.dialogVisible = newVal;
      },
      //监听子组件显隐参数
      dialogVisible(newVal) {
        newVal === false && this.$emit('close')
      }
    },
    mounted() {

    },
     
    activated() {
     
    },
    methods: {
      //选择节点事事件
      handleNodeClick(data) {
        console.log(data);
      },
      //清空选中函数
      resetChecked() {
        this.$refs.tree.setCheckedKeys([]);
      },
      //当树状选中状态发生改变时
      change(data,checked,node){
        this.selectData= this.$refs.tree.getCheckedNodes();
      },
      //删除已选择
      remove(d){
        //获得已经选择的树节点数组
        let arr = this.$refs.tree.getCheckedKeys();
        if(arr.indexOf(d.id)>=0){
          alert("所要删除的树节点id是"+d.id);
        }else{
          alert("匹配出错,请检查唯一标识!")
        }
        console.log(d,arr);
        //this.$refs.tree.setCheckedKeys([1]);
      }

    }
     
  }
</script>
<style type="text/scss" lang="scss" scoped>
  .xd{
    width: 50%;display: inline-block;
    padding-left:10px;
  }
  
  .xd:first-child{
    border-right: 1px solid;
  }
  .cc{
    padding:10px;
    display: flex;

  }
  .lj{
    cursor: pointer;
    align-self:flex-end;
    text-align:right;
    width:30%;
  }
  .df{
    display: -webkit-flex; /*在webkit内核的浏览器上使用要加前缀*/
    display: flex; 
  }
.table-c{display:table;width:100%;}
.table-c>*{display: table-cell;vertical-align: middle;text-align: center;}
</style>