/**
 * Created by Andste on 2018/6/15.
 * 颜色选择器
 * 主要用于楼层
 */

import Vue from 'vue'
import ColorPicker from './src/main'

ColorPicker.install = () => {
	Vue.component(ColorPicker.name, ColorPicker)
}

export default ColorPicker
