<template>
  <x-dialog
    v-if="dialogProxy"
    :proxy="dialogProxy"
    ref="dialogProxyComp"
  >
    <slot></slot>
    <slot name="footer"></slot>
  </x-dialog>
</template>

<script>
import XDialog from "@/components/x-dialog/x-dialog.vue";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: "ElDialogXDialog",
  components: { XDialog },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: function (n, o) {
      this.$refs.dialogProxyComp&&this.$refs.dialogProxyComp.dialogProxy[n ? "display" : "dismiss"]();
    },
    "dialogProxy.visible": function (n, o) {
      this.$emit("update:visible", n);
    },
    "$attrs.title": function (n, o) {
      this.$refs.dialogProxyComp.dialogProxy.config.title=n;
    },
  },
  data() {
    return {
      dialogProxy: null,
    };
  },
  mounted() {
    const config = {
      title: this.$attrs.title ? this.$attrs.title : "",
      disableCancel: true,
      disableConfirm: true,
    };
    if (this.$attrs.width) {
      config.width = this.$attrs.width;
    }
    // if (this.$attrs.beforeClose) {
    //   config.beforeClose = this.$attrs.beforeClose;
    // }
    this.dialogProxy = $xDialog.create(config);
  },
};
</script>
