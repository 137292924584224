const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  user: state => {
    if (sessionStorage.getItem('from_enter_seller_user') && state.user.user && !state.user.user.synced) {
      state.user.user = JSON.parse(sessionStorage.getItem('from_enter_seller_user'));
      state.user.user.synced = true;
    }
    return state.user.user;
  },
  shopInfo: state => {
    if (sessionStorage.getItem('from_enter_seller_shop') && state.user.shop && !state.user.shop.synced) {
      state.user.shop = JSON.parse(sessionStorage.getItem('from_enter_seller_shop'));
      state.user.shop.synced = true;
    }
    return state.user.shop;
  },
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  isSupplierName: state => state.user.isSupplierName
}
export default getters
