
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';

@Component({
  name: 'en-table-layout',
  components: { XDialog },
})
export default class EnTableLayout extends Vue {
  /** 是否显示加载 */
  @PropSync('loading', {
    default: () => false,
    type: Boolean
  }) isLoading!: boolean;

  @PropSync('search', {
    default: () => false,
    type: Boolean
  }) hasSearch!: boolean;

  /** 是否自定义表格 */
  @Prop({
    default: () => false,
    type: Boolean
  }) customTable!: boolean;

  /** 是否显示边框 */
  @Prop({
    default: () => true,
    type: Boolean
  }) border!: boolean;

  /** 是否显示斑纹 */
  @Prop({
    default: () => true,
    type: Boolean
  }) stripe!: boolean;

  /** 是否显示工具栏 */
  @Prop({
    default: () => true,
    type: Boolean
  }) toolbar!: boolean;

  @Prop({
    default: () => false,
    type: Boolean
  }) toolbarTwo!: boolean;

  @Prop({
    default: () => false,
    type: Boolean
  }) toolbarThree!: boolean;

  /** 是否显示分页 */
  @Prop({
    default: () => true,
    type: Boolean
  }) pagination!: boolean;

  /** 是否显示分页 */
  @Prop({
    default: () => 0,
    type: Number
  }) redundancyHeight!: number;

  @Prop({
    default: () => 0,
    type: Number
  }) pageOtherHeight !: number;

  @Prop({
    default: () => (() => { }),
    type: Function
  }) sortChangeFn;


  /** 行数据的 Key */
  @PropSync('rowKey', { default: () => '' }) rowKeySync!: string | Function;

  /** 表格数据 */
  @PropSync('tableData', { default: () => [] }) tableDataSync!: any[];

  /** 当选择项发生变化 */
  @PropSync('selectionChange', {
    default: () => {
      return () => {
      };
    }
  }) selectionChangeFn!: () => {};

  tableHeight = null as any;
  headerCellStyle = {
    backgroundColor: 'rgba(230, 236, 247, 1)'
  };
  searchDialog = $xDialog.create({
    title: '高级搜索',
    wrapperClass: 'search-dialog-container',
    beforeConfirm: () => this.handleSearch(),
  });
  isShowTable = false;
  isSearching = false;
  headerHeight = '';

  cachedHeight = 0;

  get toggleRowSelection() {
    return (this.$refs.table as any).toggleRowSelection;
  }

  @Watch('tableDataSync', { immediate: true })
  tableDataSyncChange() {
    this.$nextTick(() => {
      this.enTableHeaderChange();
      this.calcTableHeight();
    });
  }

  @Watch('$refs.enTableHeader', { immediate: true })
  enTableHeaderChange() {
    this.$nextTick(() => {
      const enTableHeader = (this.$refs.enTableHeader as any as HTMLElement);
      // const toolbar_btn = (this.$refs.toolbar_btn as any as HTMLElement);

      if (enTableHeader) {
        this.headerHeight = enTableHeader.clientHeight + 'px';
      }
    });
  }

  mounted() {
    this.isShowTable = Array.isArray(this.tableDataSync);
    if (!this.isShowTable) {
      this.$message.error('请传入数组！');
    }
  }

  @Watch('$el.clientHeight', { immediate: true })
  calcTableHeight() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (!this.$el) return;
        let parentEl = this.$el.parentElement as HTMLDivElement;

        if (
          parentEl.classList.contains('el-scrollbar__view') ||
          parentEl.parentElement?.classList.contains('el-scrollbar__view')
        ) {
          parentEl = parentEl.parentElement as any;

          if (parentEl.parentElement?.classList.contains('app-main-scroll-wrap')) {
            parentEl = parentEl.parentElement as any;
          }
        }

        if (parentEl.classList.contains('col')) {
          parentEl = parentEl.parentElement as any;
          parentEl = parentEl.parentElement as any;
        }

        const parentElStyle = getComputedStyle(parentEl);
        const headerEl = this.$el.querySelector('.en-table-header')! || { clientHeight: 0 };
        const footerEl = this.$el.querySelector('.en-table-footer')! || { clientHeight: 0 };

        if (!this.cachedHeight) {
          this.cachedHeight = parseInt(parentElStyle.height);
        }

        let height = this.cachedHeight -
          parseInt(parentElStyle.paddingTop) -
          parseInt(parentElStyle.paddingBottom);

        const minHeight = document.body.clientHeight * 0.5;

        if (height < minHeight) height = minHeight;
        if ((document as any).querySelector('.usersMsg') && (document as any).querySelector('.usersMsg')?.childElementCount > 1) {
          height = minHeight - 10
        }
        // console.log(111, height, headerEl.clientHeight, footerEl.clientHeight, 6 - this.redundancyHeight, this.pageOtherHeight)
        if (this.pageOtherHeight !== 0) {
          this.tableHeight = height - headerEl.clientHeight - footerEl.clientHeight - 6 - this.redundancyHeight - this.pageOtherHeight;
        } else {
          this.tableHeight = height - headerEl.clientHeight - footerEl.clientHeight - 6 - this.redundancyHeight
        }
        console.log('高度', this.tableHeight,)
      }, 500);
    });
  }

  handleSearch() {
    this.$emit('search');
    return true;
  }

  // 清空用户选择数据
  clearSelected() {
    console.log("ccc");
    (this.$refs.table as any).clearSelection()
  }

  toggleSearch(flag?: boolean) {
    this.isSearching = typeof flag === 'boolean' ? flag : !this.isSearching;
  }
}
