
import { Component, Vue, Watch } from 'vue-property-decorator' // , Watch
import { $layout } from '@/layouts/services/layout.service'
import { turnUrl } from '@/utils'

import * as API_order from '@/api/order'
// import * as API_login from '@/api/login'

@Component({
  name: 'app'
})
export default class App extends Vue {
  shutDown = false
  @Watch('$route', { deep: true, immediate: true })
  async routechange(to) {
    window.setTimeout(() => {
      const inputs = document.querySelectorAll('input')
      if (inputs && inputs.length > 0) {
        inputs.forEach(input => {
          input.addEventListener('input', (e) => {
            const { value } = e.target as any;
            console.log(e);

            (e.target as any).value = value.trim();
          })
        })
      }
    }, 1000);
    // , from
    if (to.name === 'goodsListComboCard') {
      API_order.getShopExtAuth().then(res => {
        // console.log(
        //   res.force_exchange_goods_open,
        //   'res.fore_exchange_goods_open',
        //   this.$store.getters.permission_routers
        // )
        this.$store.getters.permission_routers.forEach(item => {
          if (item.path === '/combo-card-shop/shop-goods') {
            // 关闭菜单
            item.children.forEach((value, index) => {
              if (value.path === 'strong-push') {
                item.children.splice(index, 1)
              }
            })
            item.children.forEach((value, index) => {
              if (value.path === 'intelligence') {
                item.children.splice(index, 1)
              }
            })
          }
        })
        // console.log('智能换货开关', res.force_exchange_goods_open, this.$store.getters.permission_routers)
        if (res.force_exchange_goods_open === 'OPEN') {
          this.$store.getters.permission_routers.forEach(item => {
            console.log(item.path)
            if (item.path === '/combo-card-shop/shop-goods') {
              // 开启菜单
              item.children.push(
                {
                  path: `strong-push`,
                  component: () =>
                    import(`@/views/combo-card-shop/goods/strongPush.vue`),
                  name: `strongPushComboCard`,
                  meta: { title: `strongPushComboCard` }
                },
                {
                  path: `intelligence`,
                  component: () =>
                    import(`@/views/combo-card-shop/goods/intelligence.vue`),
                  name: `intelligenceComboCard`,
                  meta: { title: `intelligenceComboCard` }
                }
              )
            }
          })
        }
      })
    }
  }

  mounted() {
    const { tokens, fr } = turnUrl(location.href);
    if (tokens) {
      sessionStorage.setItem('session', tokens)
      this.$store.dispatch('loginToken', { token: tokens })
    }
    if (fr) {
      localStorage.setItem('sshl__shop_fr', fr)
    }
    $layout.mode.subscribe(mode => {
      this.$el.parentElement!.className = mode
    })
  }
}
