/**
 * 自定义样式的el-select
 */
import Vue from 'vue'
import ElSelectSelect from './src/main'

ElSelectSelect.install = () => {
  Vue.component(ElSelectSelect.name, ElSelectSelect)
}

export default ElSelectSelect
