<template>
  <div class="app-wrapper">
    <navbar></navbar>
    <div class="app-content-wrapper row no-gutters" :style="{height}">
      <sidebar class="sidebar-wrapper col-auto" :style="{height}"/>
      <app-main class="app-main-wrapper col" :style="{height}"></app-main>
    </div>
  </div>
</template>

<script>
import {Navbar, Sidebar, AppMain, TagsView} from './components';
import ResizeMixin from './mixin/ResizeHandler';
import Resize from './mixin/resize';

export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView
  },
  mixins: [ResizeMixin, Resize],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === "mobile"
      };
    },
    height() {
      return (document.body.clientHeight - (80 * this.scale)) + 'px';
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('closeSideBar', {withoutAnimation: false});
    }
  }
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  overflow: scroll hidden;
}

.app-wrapper {
  height: 100vh;
  background: #F5F5F5;
  min-width: 1300px;

  .app-content-wrapper, .sidebar-wrapper, .app-main-wrapper {
    height: calc(100vh - 80px);
  }
}
</style>
