/**
 * 商品管理API
 */

import request from '@/utils/request'

/**
 * 分享设置保存
 * @param params
 * @returns {Promise<any>}
 */
export function share(params) {
  return request({
    url: `/seller/shops/share`,
    method: 'put',
    data: params,
  })
}

/**
 * 分享内容回显
 * @param params
 * @returns {Promise<any>}
 */
export function getShare(params) {
  return request({
    url: 'seller/shops/getShare',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取流水类型
 * @param params
 * @returns {Promise<any>}
 */
export function getFlowTypeList(params) {
  return request({
    url: 'seller/system/setting/getFlowTypeList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询账户流水列表, 用于企业/供应商财务-账单明细分页列表
 * @param params
 * @returns {Promise<any>}
 */
export function getAccountFlowPage(params) {
  return request({
    url: '/account/accountFlow/page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取订单结算
 * @param params
 * @returns {Promise<any>}
 */
export function getSettleOrderPage(params) {
  return request({
    url: 'seller/checkaccount/settleOrderPage',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取站点财务
 * @param params
 * @returns {Promise<any>}
 */
export function getShopChildrenList(params) {
  return request({
    url: 'seller/shops/getShopChildrenList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 导出站点财务
 * @param params
 * @returns {Promise<any>}
 */
export function getShopChilds(params) {
  return request({
    url: '/seller/shops/getShopChilds',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取交易明细
 * @param params
 * @returns {Promise<any>}A
 */
export function getAccountFlowPages(params) {
  return request({
    url: 'account/accountFlow/page',
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 获取订单结算导出
 * @param params
 * @returns {Promise<any>}
 */
export function getSettleOrderList(params) {
  return request({
    url: 'seller/checkaccount/settleOrderList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加对账表
 * @param params
 * @returns {Promise<any>}
 */
export function checkaccountAdd(data) {
  return request({
    url: '/seller/checkaccount/add',
    method: 'post',
    data,
    // headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 查询对账表
 * @param params
 * @returns {Promise<any>}
 */
export function checkaccountCaList(params) {
  return request({
    url: '/seller/checkaccount/ca-list',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询对账表
 * @param params
 * @returns {Promise<any>}
 */
export function checkaccountCaPage(params) {
  return request({
    url: '/seller/checkaccount/ca-page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询对账表内订单
 * @param params
 * @returns {Promise<any>}
 */
export function checkaccountPageCaid(params) {
  return request({
    url: '/seller/checkaccount/page-caid',
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 获取店铺商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopGoodsList(params) {
  return request({
    url: '/seller/shopGoods',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 选择供应商商品
 * @param params
 * @returns {Promise<any>}
 */
export function selectShopGoods(params) {
  return request({
    url: '/seller/shopGoods',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}


/**
 * 商品列表 删除商品  商家将商品放入回收站  下架的商品才能放入回收站
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteGoods(ids, params) {
  const _params = {}
  return request({
    url: `seller/goods/${ids}/recycle`,
    method: 'put',
    data: _params
  })
}

/**
 * 查询库存商品数据  查询商品sku（规格）信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsStockList(ids, params) {
  return request({
    url: `seller/goods/${ids}/skus`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 保存库存商品
 * @param goods_id
 * @param params
 * @returns {Promise<any>}
 */
export function reserveStockGoods(goods_id, params) {
  return request({
    url: `seller/goods/${goods_id}/quantity`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 发布商品   查询商品参数，获取所选分类关联的参数信息
 * @param category_id
 * @returns {Promise<any>}
 */
export function getGoodsParams(category_id) {
  return request({
    url: `seller/goods/category/${category_id}/params`,
    method: 'get',
    loading: false
  })
}

/**
 * 编辑商品   查询商品参数，获取所选分类关联的参数信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getEditGoodsParams(category_id, goods_id) {
  return request({
    url: `seller/goods/category/${category_id}/${goods_id}/params`,
    method: 'get',
    loading: false
  })
}

/**
 * 草稿箱编辑 查询草稿箱商品参数
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsDraftParams(ids, params) {
  return request({
    url: `seller/goods/draft-goods/${ids}/params`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询商品品类信息  ids 为category_id 商城商品品类
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCatrgory(ids, params) {
  return request({
    url: `seller/goods/${ids}/skus`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodData(ids, params) {
  return request({
    url: `seller/goods/${ids}`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getShopGoodData(ids, params) {
  return request({
    url: `seller/shopGoods/${ids}`,
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 查询单个草稿箱商品信息 草稿箱商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodDraftData(ids, params) {
  return request({
    url: `seller/goods/draft-goods/${ids}`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 品牌列表 ids为categoryid  商品发布，获取所选分类关联的品牌信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsBrandList(ids, params) {
  return request({
    url: `seller/goods/category/${ids}/brands`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 运费模板列表
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getTplList(ids, params) {
  return request({
    url: `seller/shops/ship-templates`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取积分商品分类列表
 * @param id
 */
export function getExchangeCatsList(id) {
  return request({
    url: `seller/promotion/exchange-cats/${id}/children`,
    method: 'get',
    loading: false
  })
}

/**
 * 商家下架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function underGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/under`,
    method: 'put',
    data: params
  })
}

/**
 * 商家列表下架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function changeStatus(ids, params) {
  return request({
    url: `/seller/shopGoods/${ids}/changeStatus`,
    method: 'put',
    data: params
  })
}


/**
 * 上架正常商品 商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveGoods(params) {
  return request({
    url: 'seller/goods',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editGoods(id, params) {
  return request({
    url: `seller/goods/${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 修改店铺商品价格
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function revisePrice(id, params) {
  return request({
    url: `/seller/shopGoods/${id}/revisePrice`,
    method: 'put',
    data: params,
    // headers: { 'Content-Type': 'application/json' }
  })
}


/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editShopGoods(id, params) {
  return request({
    url: `seller/shopGoods/${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 正常商品 保存草稿 草稿商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function saveDraft(params) {
  return request({
    url: `seller/goods/draft-goods`,
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 上架草稿箱商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveDraftGoods(ids, params) {
  return request({
    url: `seller/goods/draft-goods/${ids}/market`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/** 草稿箱商品 保存草稿 即 修改草稿箱商品 */
export function editDraftGoods(id, params) {
  return request({
    url: `seller/goods/draft-goods/${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 查询草稿箱sku信息
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function draftSku(id, params) {
  return request({
    url: `seller/goods/draft-goods/${id}/skus`,
    method: 'get',
    data: params
  })
}

/**
 * 获取草稿箱商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getDraftGoodsList(params) {
  return request({
    url: 'seller/goods/draft-goods',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除草稿箱商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteDraftGoods(ids, params) {
  const _params = {}
  return request({
    url: `seller/goods/draft-goods/${ids}`,
    method: 'delete',
    data: _params
  })
}

/**
 * 获取回收站商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getRecycleGoodsList(params) {
  return request({
    url: 'seller/goods',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 回收站 还原商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function RecycleReductionGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}/revert`,
    method: 'put',
    data: params
  })
}

/**
 * 回收站 彻底删除商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function RecycleDeleteGoods(ids, params) {
  return request({
    url: `seller/goods/${ids}`,
    method: 'delete',
    data: params
  })
}

/**
 * 获取预警商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getWarningGoodsList(params) {
  return request({
    url: 'seller/goods/warning',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查看预警商品库存信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getWarningGoodsStockList(ids, params) {
  return request({
    url: `seller/goods/${ids}/skus`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取分销设置
 */
export function getDistributionSet() {
  return request({
    url: 'seller/distribution/setting',
    method: 'get',
    loading: false
  })
}

/**
 * 获取分销返利信息
 * @param id
 */
export function getDistributionInfo(id) {
  return request({
    url: `seller/distribution/goods/${id}`,
    method: 'get',
    loading: false
  })
}

/**
 * 保存分销返利
 * @param params
 */
export function setDistributionInfo(params) {
  return request({
    url: 'seller/distribution/goods',
    method: 'put',
    data: params
  })
}


/*
	企业供应商查询余额
*/
export function getShopBalance() {
  return request({
    url: '/seller/shops/get-shop-balance',
    method: 'get'
  })
}

/*
	智能套餐卡查询消耗总额
*/
export function getEnterprisePriceTh() {
  return request({
    url: '/seller/shops/getEnterprisePriceTh',
    method: 'get'
  })
}

/*
	查询提现管理列表
*/

export function getBalanceList(params) {
  return request({
    url: 'account/cashOut/page',
    method: 'post',
    data: params
  })
}


/*
	查询查询账户提现审核流水管理列表
*/
export function getCashOutLogList(params) {
  return request({
    url: 'account/cashOutLog/page',
    method: 'post',
    params
  })
}

/*
	查询账户提现管理详情，用于企业 / 供应商查看提现数据
*/
export function getBalanceDetail(id) {
  return request({
    url: `account/cashOut/${id}`,
    method: 'get',
  })
}

/*
	支付前生成预支付单
*/

export function createOrder(params) {
  return request({
    url: 'seller/trade/serviceOrder/addServiceOrder',
    method: 'put',
    data: params
  })
}

/*
	支付
*/

export function pay(params, sn) {
  return request({
    url: `seller/payment/serviceOrder/service/${sn}`,
    method: 'get',
    params
  })
}

/*
	线下支付
*/
export function payDown(params, sn) {
  return request({
    url: `seller/payment/serviceOrder/service/offline/${sn}`,
    method: 'get',
    params
  })
}

/*
	转账到预付款里
*/
export function tranLateMoney(params) {
  return request({
    url: "account/cashOut/trans-account",
    method: 'post',
    data: params
  })
}

/*
	查询账户提现审核流水管理列表
*/

export function txHistList(params) {
  return request({
    url: "account/cashOutLog",
    method: 'get',
    params
  })
}

/*
	查询提现账户
*/

export function getAccountList(params) {
  return request({
    url: "account/cashAccount/page",
    method: 'post',
    data: params
  })
}

/*
	获得提现账户列表
*/

export function getXlist(params) {
  return request({
    url: "account/cashAccount/list",
    method: 'post',
    params
  })
}

/*
	修改店铺预付款账户预警值
*/
export function editShopYuJing(params) {
  return request({
    url: "seller/shops/edit-shop-warn",
    method: 'post',
    data: params
  })
}

/*
	修改店铺设置
*/
export function editShopSetting(params) {
  return request({
    // url: "seller/shops",
    url: "/seller/shops/editTh",
    method: 'put',
    data: params
  })
}

/*
	获取店铺信息
*/
export function getShopmsg() {
  return request({
    // url: "seller/shops",
    url: "/seller/shops/getShopTh",
    method: 'get',
  })
}

/**
 * 获取店铺升级信息
 * @param params
 */
export function getShopUpInfo(params) {
  return request({
    url: '/seller/shops/getUpInfo',
    method: 'get',
    params
  })
}

/*
	查询首页概览

*/

export function getGaiLan(params) {
  return request({
    url: "account/accountFlow/census",
    method: 'get',
    params
  })
}

/*
	添加账号
*/

export function addAccount(params) {

  return request({
    url: "account/cashAccount/add",
    method: 'post',
    data: params,
    // headers: { 'Content-Type': 'application/json' }
  })
}

/*
	删除账号
*/

export function deleteAccount(id) {

  return request({
    url: `account/cashAccount/${id}`,
    method: 'delete'
  })
}

/*
	修改体现账户
*/
export function editAccount(params) {

  return request({
    url: "account/cashAccount/edit",
    method: 'post',
    data: params
  })
}

/*
	财务汇总分页
*/
export function getHuiZong(params) {

  return request({
    url: "/account/accountFlow/coll-page",
    method: 'get',
    params
  })
}

/*
	财务汇总不分页
*/
export function getHuiZongList(params) {

  return request({
    url: "/account/accountFlow/coll-list",
    method: 'get',
    params
  })
}

/**
 导出
 */
export function getAccountFlowList(params) {
  return request({
    url: 'account/accountFlow/list',
    method: 'get',
    params
  })
}

/*
   短信概览
*/
export function Management() {
  return request({
    url: '/seller/shops/smsSign/getSmsCount',
    method: 'get',
  })
}

/*
   添加短信签名表
*/
export function addManage(params) {
  return request({
    url: '/seller/shops/smsSign',
    method: 'post',
    data: params
  })
}

/*
   短信签名管理 查询短息签名列表
*/
export function ManageList(params) {
  return request({
    url: '/seller/shops/smsSign',
    method: 'get',
    params
  })
}

/*
   短信签名管理 修改短息签名列表
*/
export function putManage(id, params) {
  return request({
    url: `/seller/shops/smsSign/${id}`,
    method: 'put',
    params
  })
}

/*
   短信签名管理 查询短息签名列表
*/
export function ManageListAll(params) {
  return request({
    url: '/seller/shops/smsSign/listAll',
    method: 'get',
    params
  })
}

/*
   查询短息签名详情
*/
export function ManageMsg(params) {
  return request({
    url: '/seller/shops/smsSign{id}',
    method: 'get',
    params
  })
}

/*
   修改短信签名表
*/
export function changeManage(params) {
  return request({
    url: '/seller/shops/smsSign{id}',
    method: 'put',
    data: params
  })
}

/*
   修改店铺短信签名表
*/
export function changeDPManage(params, shop_id) {
  return request({
    url: `/seller/shops/smsSign/editShopSign/${shop_id}`,
    method: 'put',
    data: params,
    // headers: { 'Content-Type': 'application/json' }
  })
}

/*
   查询企业下店铺的短信签名
*/
export function getDPManage(params) {
  return request({
    url: '/seller/shops/smsSign/getShoipSmsSign',
    method: 'get',
    params
  })
}

/*
   短信签名配置，所有店铺短信签名配置列表
*/
export function pzManagelist(params) {
  return request({
    url: '/seller/shops/smsSign/listAllChildShop',
    method: 'get',
    params
  })
}

/*
   短信签名配置，所有店铺短信签名配置列表
*/
export function getPayResult(params, sn) {
  return request({
    url: `/seller/payment/serviceOrder/pay/query/service/${sn}`,
    method: 'get',
    params
  })
}

/**
 支付异步回调
 */
export function callBack(urls, params) {
  return request({
    url: urls,
    method: 'post',
    params
  })
}

/**
 支付异步回调
 */
export function paymentServiceOrder(sn, params) {
  return request({
    url: `seller/payment/serviceOrder/service/${sn}`,
    method: 'get',
    params
  })
}

/**
 企业认证修改
 */
export function editShopInfo(params) {
  return request({
    url: 'seller/shops/editShopInfo',
    method: 'post',
    params
  })
}

/**
 查询质保金变化记录信息列表
 */
export function retentionMoneyList(params) {
  return request({
    url: 'seller/shops/retention/getList',
    method: 'get',
    params
  })
}

/**
 供应商获取审核发票列表
 */
export function getAuditList(params) {
  return request({
    url: 'seller/shops/shopReceiptHistory/getAuditList',
    method: 'get',
    params
  })
}

/**
 供应商审核发票
 */
export function aduditHitory(params) {
  return request({
    url: 'seller/shops/shopReceiptHistory/aduditHitory',
    method: 'put',
    params
  })
}

/**
 供应商获取发票信息
 */
export function getInfo(history_id) {
  return request({
    url: `seller/shops/shopReceiptHistory/getInfo/${history_id}`,
    method: 'get',
  })
}

/**
 供应商获取发票抬头和发票税号
 */
export function getTitleAndTaxNo() {
  return request({
    url: `seller/shops/shopReceiptHistory/getDetail`,
    method: 'get',
  })
}

/**
 供应商获取发票订单
 */
export function getOrderList(params) {
  return request({
    url: `seller/shops/shopReceiptHistory/getOrderList`,
    method: 'get',
    params
  })
}

/**
 获取平台给供应商最充值预付款的待审核信息
 */
export function getShopBalanceLog(params) {
  return request({
    url: `/seller/shops/getShopBalanceLog`,
    method: 'put',
    params
  })
}


/**
 供应商审核平台打的预付款
 */
export function auditShopBalance(id, params) {
  return request({
    url: `/seller/shops/auditShopBalance/${id}`,
    method: 'put',
    params
  })
}

/**
 查看供应商预付款充值记录
 */
export function GET_shopBalanceLog(params) {
  return request({
    url: `/seller/shops/shopBalanceLog/page`,
    method: 'get',
    params
  })
}


/**
 查询所有短信套餐表列表
 */
export function GET_shopSmsgetAll(params) {
  return request({
    url: `/seller/shops/shopSms/getAll`,
    method: 'get',
    params
  })
}

/**
 供应商邮寄发票
 */
export function postReceiptByHistoryId(history_id, data) {
  return request({
    url: `/seller/shops/shopReceiptHistory/postReceipt/${history_id}`,
    method: 'put',
    data
  })
}

/**
 * 获取快递公司列表
 */
export function getExpressCompany() {
  return request({
    url: 'seller/shops/logi-companies',
    method: 'get'
  })
}

/**
 * 获取快递
 */
export function getExpressDetail(params) {
  return request({
    url: 'seller/express',
    method: 'get',
    params
  })
}
/**
 * 查询礼包标签列表
 */
export function getGiftLabelList(params) {
  return request({
    url: 'seller/giftLabel/list',
    method: 'get',
    params
  })
}
/**
 * 根据三方域名查询基础信息
 */
export function getDomainByBasicInformation(domain) {
  return request({
    url: 'seller/shops/getDomainByBasicInformation',
    method: 'get',
    params:{domain:domain}
  })
}