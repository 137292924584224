<template>
	<div class="tags-view-container">
		<scroll-pane class='tags-view-wrapper' ref='scrollPane'>
			<router-link ref='tag' class="tags-view-item" :class="isActive(tag)?'active':''" v-for="tag in Array.from(visitedViews)"
									 :to="tag" :key="tag.path" @contextmenu.prevent.native="openMenu(tag,$event)">
				{{ generateFormatTitle(tag) }}
				<span v-if="tag.name !== 'dashboard'" class='el-icon-close' @click.prevent.stop='closeSelectedTag(tag)'></span>
			</router-link>
		</scroll-pane>
		<ul class='contextmenu' v-show="visible" :style="{left:left+'px',top:top+'px'}">
			<li @click="closeSelectedTag(selectedTag)">{{$t('tagsView.close')}}</li>
			<li @click="closeOthersTags">{{$t('tagsView.closeOthers')}}</li>
			<li @click="closeAllTags">{{$t('tagsView.closeAll')}}</li>
		</ul>
	</div>
</template>

<script>
	import ScrollPane from '@/components/ScrollPane'
	import { generateTitle } from '@/utils/i18n'

	export default {
		components: { ScrollPane },
		data() {
			return {
				visible: false,
				top: 0,
				left: 0,
				selectedTag: {}
			}
		},
		computed: {
			visitedViews() {
				return this.$store.state.tagsView.visitedViews
			}
		},
		watch: {
			$route() {
				this.addViewTags()
				this.moveToCurrentTag()
			},
			visible(value) {
				if (value) {
					document.body.addEventListener('click', this.closeMenu)
				} else {
					document.body.removeEventListener('click', this.closeMenu)
				}
			}
		},
		mounted() {
			this.addViewTags()
		},
		methods: {
			generateTitle, // generateTitle by vue-i18n
			generateFormatTitle(tag) {
				if (tag.name === 'tagAdd') { // 添加标签商品
					return `${this.generateTitle(tag.title)} - ${tag.query.tag_name}`
				}
				if (tag.name === 'groupBuyGoods') { // 编辑/新增团购活动
					return `${tag.query.group_name}${this.generateTitle(tag.title)}`
				}
				return this.generateTitle(tag.title)
			},
			generateRoute() {
				if (this.$route.name) {
					return this.$route
				}
				return false
			},
			isActive(route) {
				return route.path === this.$route.path// || route.name === this.$route.name
			},
			addViewTags() {
				const route = this.generateRoute()
				if (!route) {
					return false
				}
				this.$store.dispatch('addVisitedViews', route)
			},
			moveToCurrentTag() {
				const tags = this.$refs.tag
				this.$nextTick(() => {
					for (const tag of tags) {
						if (tag.to.path === this.$route.path) {
							this.$refs.scrollPane.moveToTarget(tag.$el)
							break
						}
					}
				})
			},
			closeSelectedTag(view) {
				this.$store.dispatch('delVisitedViews', view).then((views) => {
					if (this.isActive(view)) {
						const latestView = views.slice(-1)[0]
						if (latestView) {
							this.$router.push(latestView)
						} else {
							this.$router.push('/')
						}
					}
				})
			},
			closeOthersTags() {
				this.$router.push(this.selectedTag)
				this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
					this.moveToCurrentTag()
				})
			},
			closeAllTags() {
				this.$store.dispatch('delAllViews')
				this.$router.push('/')
			},
			openMenu(tag, e) {
				this.visible = true
				this.selectedTag = tag
				this.left = e.clientX
				this.top = e.clientY
			},
			closeMenu() {
				this.visible = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../plugins/variables";
	.tags-view-container {
		.tags-view-wrapper {
			background: #fff;
			height: 62px;
			.tags-view-item {
				display: inline-block;
				position: relative;
				height: 48px;
				line-height: 48px;
				border: 1px solid #e9eef3;
				color: #333333;
				background: #fff;
				padding: 0 10px;
				min-width: 108px;
				font-size: 16px;
				margin-left: 20px;
				margin-top: 7px;
				border-radius: 10px;
				&:first-of-type {
					margin-left: 40px;
				}
				&.active {
					background-color: #1a43a9;
					color: #fff;
					border-color: #1a43a9;
				}
			}
		}
		.contextmenu {
			margin: 0;
			background: #fff;
			z-index: 999;
			position: absolute;
			list-style-type: none;
			padding: 5px 0;
			border-radius: 4px;
			font-size: 12px;
			font-weight: 400;
			color: #333;
			box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
			li {
				margin: 0;
				padding: 7px 16px;
				cursor: pointer;
				&:hover {
					background: #eee;
				}
			}
		}
		.tags-view-item {
			padding: 0 10px;
			min-width: 80px;
			border-radius: 4px;
			text-align: center;
			user-select: none;
			.el-icon-close {
				vertical-align: 2px;
				width: 20px;
				font-size: 28px;
				border-radius: 50%;
				text-align: center;
				transition: all .3s cubic-bezier(.645, .045, .355, 1);
				transform-origin: 100% 50%;
				&:before {
					transform: scale(.6);
					display: inline-block;
					vertical-align: -6px;
				}
			}
		}
	}
</style>
