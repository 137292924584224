/* eslint-disable */
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {MessageBox} from 'element-ui'
import Storage from '@/utils/storage'
import * as API_Shop from '@/api/shop'
import * as API_order from '@/api/order'
import {domain} from '@/../ui-domain';
import { getStorage } from './storage';
import {turnUrl} from "@/utils";
// import Vue from "vue";

const whiteList = ['/login']

async function routerBeforeEach(to, from, next) {
  NProgress.start()
  let refreshToken = '';
  const tokens = turnUrl(location.href).tokens;
  refreshToken = Storage.getItem('seller_refresh_token');
  if (!refreshToken && tokens) {
    refreshToken = await getStorage('seller_refresh_token')
    Storage.setItem('seller_refresh_token', await getStorage('seller_refresh_token'));
    Storage.setItem('seller_user', await getStorage('seller_user'));
    location.reload();
  }

  if (refreshToken) {
    if (to.path === '/login') {
      next({path: '/'})
      NProgress.done();
    } else {
      // 获取店铺状态
      let status = await API_Shop.getShopStatus();
      // 获取店铺是否拥有显示供应商名称的权限
      const isSupplierName = await API_order.getShopExtAuth()
      //如果站点已经被删除就退出登录
      if (isSupplierName&&isSupplierName.shop_disabled === 1) { 
        store.dispatch("logoutAction").then(() => {
          window.location.href = "/login?type=combo-card-shop";
        });
      }
      store.dispatch('setIsSupplierName', isSupplierName.supplier_name_open);
      console.log(isSupplierName.supplier_name_open, 'isSupplierName')
      if (!status) {
        sessionStorage.removeItem('session');
        status = await API_Shop.getShopStatus();
      }

      if (status === 'OPEN' || status === 'CLOSED') {
        // let rulesArray = [];
        const shopInfo = Storage.getItem('seller_shop')
        if (!shopInfo) await store.dispatch('getShopInfoAction');
        if (store.getters.addRouters.length === 0) {
          store.dispatch('GenerateRoutes').then(() => {
            const {addRouters} = store.getters;

            router.addRoutes(addRouters);

            next({
              path: to.path ?? '/',
              replace: true
            })
          }).catch((e) => {
            console.log(e);

            errorMsg('验证失败，请重新登录！', '登录出错')
          })
        } else {
          next()
        }
      } else if (status === 'CLOSED') {
        errorMsg('当前商城已被禁用，无法登录。如需登录，请联系平台管理人员。')
      } else if (status === 'APPLY' || status === 'APPLYING') {
        let shopInfo = Storage.getItem('seller_shop')
        if (!shopInfo) {
          await store.dispatch('getShopInfoAction')
          shopInfo = Storage.getItem('seller_shop')
        }
        shopInfo = shopInfo ? JSON.parse(shopInfo) : {}
        if (shopInfo.shop_type === 2) {
          location.replace(`${domain.buyer_pc}/login_seller?forward=%2Fshop%2Fapply%2Fuser-agreement%3Ftype%3Dsupplier&type=supplier&name=${shopInfo.member_name}`)
        } else {
          errorMsg('您的店铺正在申请中，请稍后再试！')
        }
      } else if (status === 'REFUSED') {
        errorMsg('您的开店申请被拒绝，请重新申请或联系管理员！')
      } else if (status === 'EXPIRED') {
        errorMsg('当前商城已到期，无法登录。如需登录，请联系平台管理人员进行充值续费')
      } else {
        if (location.href.includes('login')) {
          Storage.clearAll();
        } else {
          store.dispatch('fedLogoutAction')
          MessageBox.confirm('账号尚未申请开店！', '出现错误', {
            confirmButtonText: '去开店',
            cancelButtonText: '取消',
            type: 'error'
          }).then(() => {
            location.replace(`${domain.buyer_pc}/shop/apply`)
          })
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      console.log(666)
      next(`/login?forward=${location.pathname}`);
      NProgress.done();
    }
  }
}

const errorMsg = (msg, title = '权限错误') => {
  store.dispatch('fedLogoutAction')
  MessageBox.alert(msg, title, {
    type: 'error',
    callback: () => {
      console.log(555)
      location.replace(`/login?forward=${location.pathname}`)
    }
  })
}

router.beforeEach(routerBeforeEach)

router.afterEach((info) => {
  const n = info.matched
  if (n && n.length && !n[0].meta.nullChild) {
    store.dispatch('toggleMenuOpen', true)
  } else {
    store.dispatch('toggleMenuOpen', false)
  }
  NProgress.done()
});
