/**
 * Created by Andste on 2018/7/2.
 * buyer_pc : 买家PC端
 * buyer_wap: 买家WAP端
 * seller   : 商家中心
 * admin    : 后台管理
 */

const env = process.server
  ? process.env
  : (global.window ? window.__NUXT__.state.env : {})

module.exports = {
  // 开发环境
  dev: {
    buyer_pc: 'https://127.0.0.1:6601',
    buyer_wap: 'https://127.0.0.1:6602',
    seller: 'https://127.0.0.1:6603',
    admin: 'https://127.0.0.1:6604'
  },
  // 生产环境
  pro: {
    buyer_pc: env.DOMAIN_BUYER_PC || 'https://fxshop.sshlqf.com',
    buyer_wap: env.DOMAIN_BUYER_WAP || 'https://m.fxshop.sshlqf.com',
    seller: env.DOMAIN_SELLER || 'https://fxseller.sshlqf.com',
    admin: env.DOMAIN_ADMIN || 'https://fxmanager.sshlqf.com'
  }
}
