import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import permission from './modules/permission';
import tagsView from './modules/tagsView';
import * as user from './modules/user';
import getters from './getters';
import {turnUrl} from '@/utils';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    permission,
    tagsView,
    user
  },
  getters
});

(() => {
  const tokens = turnUrl(location.href).tokens;

  if (tokens) { // uuid
    sessionStorage.setItem('session', tokens);
    store.dispatch('loginToken', {token: tokens});
  }
})();

export default store;
