
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component({
  name: 'x-sortable'
})
export default class XSortable extends Vue {
  @PropSync('sort', {default: () => '-'}) sortValue!: 'asc' | 'desc' | '' | '-';
  @Prop({default: () => ''}) defaultValue!: string;
  isLocalMode = false;
  localSortValue = '';

  ascClass = {active: false};
  descClass = {active: false};

  mounted() {
    this.localSortValue = this.defaultValue;

    if (this.defaultValue) {
      this.detectActive(this.defaultValue);
    }
  }

  toggle() {
    this.isLocalMode = this.sortValue === '-';

    let value: any;

    if (this.isLocalMode) {
      value = this.detectValue(this.localSortValue);
      this.localSortValue = value;
      this.sortValue = value;
    } else {
      value = this.detectValue(this.sortValue);
      this.sortValue = value;
    }

    this.detectActive(value);
  }

  detectActive(value) {
    this.ascClass.active = value === 'asc';
    this.descClass.active = value === 'desc';
  }

  detectValue(value) {
    switch (value) {
      case 'asc':
        return 'desc';
      case 'desc':
        return '';
      case '':
        return 'asc';
      default:
        return '';
    }
  }
}
